import React from 'react';
import { navigate, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Card = ({ frontmatter, slug }) => {
  const handlePasswordPrompt = () => {
    let password = 'joeoak2021';
    let passwordPrompt = prompt('Password', '');
    if (passwordPrompt === password) navigate(slug);
  };

  const returnLink = () => {
    if (frontmatter.altLinks) {
      return frontmatter.altLinks.map((link, index) => (
        <a href={link.url} key={index}>
          {link.label}
        </a>
      ));
    } else {
      if (frontmatter.confidential) {
        return (
          <button className='case-study-button' onClick={handlePasswordPrompt}>
            View project 🔒
          </button>
        );
      } else {
        return <Link to={slug}>View project</Link>;
      }
    }
  };

  return (
    <div className='card'>
      <div className='card-content'>
        <GatsbyImage alt={'Case study thumbnail image'} image={getImage(frontmatter.thumbnail)} />
        <h3>{frontmatter.title}</h3>
        {frontmatter.shortDescription ? <p>{frontmatter.shortDescription}</p> : ''}
        <p className='links'>{returnLink()}</p>
      </div>
    </div>
  );
};

export default Card;
