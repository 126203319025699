import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Card from '../components/card';
import * as Icons from '../components/icons';
import Layout from '../components/layout';

const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle='Home'>
      <section>
        <h1>
          Joe Oak
          <br /> — Designer at Microsoft
          <br /> Based in Seattle, WA
        </h1>
        <div className='social'>
          <span className='profile-photo'>
            <StaticImage src='../images/profile.png' alt='Profile photo' />
          </span>
          <div className='social-links'>
            <a href='https://www.figma.com/@joeoak'>Figma</a>
            <a href='https://github.com/joeoak'>GitHub</a>
            <a href='https://www.linkedin.com/in/josephoak'>LinkedIn</a>
            <a href='./oak-joe-resume-2021.pdf'>Resume</a>
          </div>
        </div>
        <p>Multi-disciplinary designer with a keen visual sense and front-end development skills. {new Date().getFullYear() - 2015}+ years of experience designing products, design systems, and websites. While at R/GA, I worked on projects for clients like Fossil, Uber, and Verizon. At Microsoft, I work on the Microsoft Edge browser, aspiring to help our customers save time on the web.</p>
      </section>
      <section className='full-width'>
        <h2>Case studies</h2>
        <div className='cards'>
          {data.allMarkdownRemark.edges.map(({ node }, index) => {
            // prettier-ignore
            return node.frontmatter.type === 'Case study' ?
              <Card 
                frontmatter={node.frontmatter} 
                key={index} 
                slug={node.fields.slug}
              />
            :
              '';
          })}
        </div>
      </section>
      <section className='full-width'>
        <h2>Personal projects</h2>
        <div className='cards'>
          {data.allMarkdownRemark.edges.map(({ node }, index) => {
            // prettier-ignore
            return node.frontmatter.type === 'Personal project' ?
              <Card 
                frontmatter={node.frontmatter} 
                key={index} 
                slug={node.fields.slug}
              />
            :
              '';
          })}
        </div>
      </section>
      <footer>
        <span>©{new Date().getFullYear()}</span>
        <span className='pelican-icon'>
          <Icons.Pelican />
        </span>
        <span>
          Built with <a href='https://www.gatsbyjs.com/'>Gatsby</a>
        </span>
      </footer>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___year], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            confidential
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
            altLinks {
              label
              url
            }
            shortDescription
            title
            type
            year
          }
        }
      }
    }
  }
`;
