import React from 'react';

export const Pelican = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path d='M12.5289 6.26417C13.005 6.26417 13.391 5.87822 13.391 5.40212C13.391 4.92602 13.005 4.54007 12.5289 4.54007C12.0528 4.54007 11.6669 4.92602 11.6669 5.40212C11.6669 5.87822 12.0528 6.26417 12.5289 6.26417Z' fill='black' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5976 5.40218C4.5976 5.92366 4.67139 6.4278 4.8091 6.90475L0 3.9679V12.2984C0 16.5358 3.4636 19.9994 7.70098 19.9994L10 19.9995L10.0352 19.9994H12.299C16.5364 19.9994 20 16.5358 20 12.2984V3.9679L15.2247 6.88411C15.3376 6.37897 15.402 5.88147 15.402 5.40218C15.402 2.41765 12.9843 0 9.99977 0C7.01524 0 4.5976 2.41765 4.5976 5.40218ZM9.99977 1.60916C7.90396 1.60916 6.20676 3.30636 6.20676 5.40218C6.20676 7.08498 7.30142 8.5114 8.81752 9.00772L9.20333 6.66627C8.7843 6.40159 8.50601 5.93433 8.50601 5.40206C8.50601 4.57683 9.17499 3.90785 10.0002 3.90785V4.94789C11.1062 6.01637 12.1144 7.19613 13 8.45349C13.5004 7.3091 13.7928 6.26076 13.7928 5.40218C13.7928 3.30636 12.0956 1.60916 9.99977 1.60916ZM9.62795 13.9869L10.6164 7.98816C11.1316 8.60922 11.6872 9.34441 12.1726 10.1095C11.3937 11.5115 10.4465 12.888 9.62795 13.9869ZM8.93767 17.4271C10.4701 15.6608 11.8695 13.7616 13.0771 11.7576C13.5142 12.7139 13.793 13.6842 13.793 14.5973C13.793 16.6732 12.1104 18.3743 10.0295 18.3902H7.70098C5.09242 18.3902 2.8513 16.7256 1.98808 14.4069L5.4024 16.492V14.6065L1.60916 12.29V10.5059L5.4024 12.8224V10.9369L1.60916 8.62041V6.83608L8.48361 11.0342L7.53927 16.7654L8.93767 17.4271ZM14.0189 10.063C14.1511 9.8012 14.2768 9.5391 14.3954 9.27604L18.3908 6.83608V8.62052L16.3217 9.88412V11.7696L18.3908 10.506V12.2901L16.3217 13.5537V15.4392L18.0119 14.4071C17.3551 16.1711 15.9007 17.5566 14.0938 18.1184C14.9093 17.1711 15.4022 15.9394 15.4022 14.5973C15.4022 13.0394 14.8014 11.4626 14.0189 10.063Z'
        fill='black'
      />
    </svg>
  );
};
